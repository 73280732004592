import React, { useState } from "react";
import { FiChevronRight } from "react-icons/fi";

import Button from "../../../components/Button";
import CardFull from "../../../components/Cards/CardFull";
import ConsultationByPeriod from "../../../components/ConsultationByPeriod";
import Filters from "../../../components/Filters";
import InputSearch from "../../../components/Input/InputSearch";
import ProcessInfo from "../../../components/ProcessInfo";

const Submenu = ({ processQty }) => {
    const [actionFilter, setActionFilter] = useState(false);

    return (
        <>
            <CardFull className="justify-between flex xs:flex-wrap lg:flex-wrap flex-col-reverse">
                <div className="flex xs:w-full lg:w-3/5 items-center">
                    <Button className="mr-4 font-medium h-10" onClick={() => setActionFilter(!actionFilter)}>
                        filtros <FiChevronRight size="1.1em" />
                    </Button>
                    <ConsultationByPeriod />
                    <div className="ml-5">
                        <ProcessInfo information={processQty} />
                    </div>
                </div>
                <div className="xs:w-full lg:w-2/5 xs:mb-2 lg:mb-0">
                    <InputSearch placeholder="buscar por processo, empresa ou ref." />
                </div>
            </CardFull>
            {actionFilter && <Filters setActionFilter={setActionFilter} actionFilter={actionFilter} />}
        </>
    );
};
export default Submenu;
